import React from "react";
import theme from "theme";
import { Theme, Em, Text, Box, Section, LinkBox, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				OakBridge Legal Solutions
			</title>
			<meta name={"description"} content={"Gerechtigkeit stärken, Seelenfrieden fördern"} />
			<meta property={"og:title"} content={"OakBridge Legal Solutions"} />
			<meta property={"og:description"} content={"Gerechtigkeit stärken, Seelenfrieden fördern"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb6248d4a0c0020318f97/images/1-2.jpg?v=2024-07-12T08:50:46.728Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="link4" />
			<Override slot="link3" />
		</Components.Header2>
		<Components.Hero>
			<Override slot="text1">
				<Em>
					Umfassende Rechtsdienstleistungen, die auf Ihre Bedürfnisse zugeschnitten sind
				</Em>
			</Override>
			<Override slot="text">
				Unsere wichtigsten Rechtsdienstleistungen
			</Override>
		</Components.Hero>
		<Section
			padding="80px 0 90px 0"
			quarkly-title="Headline-4"
			border-width="0 0 .1px 0"
			border-style="solid"
			border-color="--color-darkL2"
		>
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="block" lg-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="100%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="--lead"
						color="--dark"
						sm-margin="0px 0px 25px 0px"
						width="100%"
					>
						Bei OakBridge Legal Solutions bieten wir ein umfassendes Spektrum an Rechtsdienstleistungen, die auf die Bedürfnisse einer Vielzahl von Kunden zugeschnitten sind, von individuellen Rechtsangelegenheiten bis hin zu Unternehmensrechtsstrategien. Unser Ziel ist es, zuverlässige, effiziente und effektive Rechtslösungen bereitzustellen, die Ihre Interessen schützen und Ihre Rechte fördern.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-9">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Box
				min-width="10px"
				min-height="10px"
				display="flex"
				justify-content="space-between"
				align-items="center"
				margin="0px 0px 70px 0px"
				lg-flex-direction="column"
				text-align="center"
				flex-direction="column"
				max-width="100%"
			>
				<Text margin="0px 0px 20px 0px" color="--darkL1" font="--lead" width="70%">
					Entdecken Sie die Tiefe und Breite unserer Dienstleistungen, die alle darauf ausgelegt sind, die größte Sorgfalt und fachmännische Bearbeitung Ihrer Rechtsangelegenheiten zu gewährleisten. Die umfassende Erfahrung unseres Teams in verschiedenen Rechtsbereichen ermöglicht es uns, Ihnen spezialisierte Unterstützung anzubieten, die auf Ihre spezifischen Bedürfnisse zugeschnitten ist.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
				align-content="center"
				align-items="center"
				justify-items="center"
				justify-content="center"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					background="--color-darkL2"
					height="480px"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Gesellschaftsrecht
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Unternehmensgründung: Unterstützung bei der Auswahl, Gründung und rechtlichen Strukturierung der Rechtsform.
Verträge: Ausarbeitung, Überprüfung und Verhandlung von Verträgen zum Schutz Ihrer Geschäftsinteressen.
Fusionen und Übernahmen: Bewältigung komplexer Transaktionen mit strategischer Planung und Durchführung.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Familienrecht
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Scheidungsprozesse: Führung sensibler Verhandlungen mit Diskretion und Einfühlungsvermögen.
Sorgerecht: Eintreten für Ihre elterlichen Rechte und das Wohl Ihrer Kinder.
Nachlassplanung: Sicherstellung der ordnungsgemäßen Verwaltung und Verteilung Ihres Nachlasses.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Strafverteidigung
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Verteidigungsstrategie: Ausarbeitung starker Verteidigungsstrategien für eine Reihe von Strafanzeigen.
Verhandlungen über ein Schuldeingeständnis: Aushandlung von Schuldeingeständnissen, um Anklagepunkte und Strafen nach Möglichkeit zu reduzieren.
Vertretung vor Gericht: Aggressive Vertretung vor Gericht zur Verteidigung Ihrer Rechte und Freiheiten.
						</Text>
					</LinkBox>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
				grid-gap="0 35px"
				lg-grid-gap="40px 35px"
				md-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Geistiges Eigentum
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Patentdienstleistungen: Beratung zu Patentanmeldung, -schutz und -streitigkeiten.
Markenregistrierung: Sicherung und Schutz Ihrer Markenidentität durch Marken.
Urheberrecht: Hilfe beim Schutz Ihrer Originalwerke vor unbefugter Nutzung.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					padding="30px 30px 30px 30px"
					height="480px"
					background="--color-darkL2"
					grid-column="span 2"
				>
					<LinkBox align-items="center" margin="0px 0px 0 0px" href="/">
						<Text
							margin="0px 0px 2rem 0px"
							font="--headline3"
							color="--light"
							text-align="center"
							height="auto"
						>
							Wir sind bereit, Ihnen zu helfen
						</Text>
						<Text
							margin="0px 0px 0 0px"
							font="--base"
							color="--light"
							text-align="center"
							height="auto"
						>
							Entdecken Sie das gesamte Spektrum unserer Angebote, indem Sie uns kontaktieren oder unsere Büros besuchen. Unser engagiertes Team ist bereit, Ihnen detaillierte Informationen und maßgeschneiderte Beratung speziell für Ihre rechtlichen Bedürfnisse zu geben. Diese Übersicht ist nur ein kleiner Einblick, wie wir Sie durch umfassende Rechtsdienstleistungen unterstützen und stärken können.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline3" md-margin="0px 0px 30px 0px">
					Kontaktieren Sie uns
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
					Machen Sie den ersten Schritt zur Lösung Ihrer rechtlichen Herausforderungen, indem Sie sich an OakBridge Legal Solutions wenden. Unsere Experten sind hier, um Sie mit strategischer Beratung und solider rechtlicher Unterstützung zu unterstützen.
				</Text>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text" font="--headline2" text-transform="uppercase">
				OakBridge Legal Solutions
			</Override>
			<Override slot="text1" text-transform="uppercase" font="--headline3">
				Ihr Tor zu juristischer Exzellenz und Seelenfrieden
			</Override>
		</Components.Cta>
		<Components.Header2>
			<Override slot="text">
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					CycleCare Pro
				</Strong>
			</Override>
		</Components.Header2>
		<Components.Footer>
			<Override slot="text" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});